import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b5dbda6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 1,
  class: "spectating"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Game = _resolveComponent("Game")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.isSpectating)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allSpectationEntries, (entry) => {
            return (_openBlock(), _createElementBlock("button", {
              key: entry.clientID,
              onClick: ($event: any) => {_ctx.spectatePlayer(entry.clientID); _ctx.showSpectatedGame();}
            }, _toDisplayString(entry.playerName), 9, _hoisted_2))
          }), 128)),
          (_ctx.allSpectationEntries.length === 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "No players to spectate."))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.isSpectating)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => {_ctx.showSpectationEntries();})
          }, "Spectate Others"),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.playerNameVisualsMap, ([playerName, visuals]) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "game",
              key: playerName
            }, [
              _createVNode(_component_Game, {
                playerGameVisuals: visuals,
                areRef: false,
                gameMode: _ctx.GameMode.Sprint
              }, null, 8, ["playerGameVisuals", "gameMode"])
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}