import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "replay",
  class: "page"
}
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { class: "page-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Game = _resolveComponent("Game")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", null, "ReplayId: " + _toDisplayString($setup.replayId), 1),
        _createElementVNode("button", {
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($setup.playReplay && $setup.playReplay(...args)))
        }, "Play Replay"),
        _createVNode(_component_Game, {
          playerGameVisuals: $setup.gameVisuals,
          areRef: false,
          gameMode: $setup.GameMode.Sprint
        }, null, 8, ["playerGameVisuals", "gameMode"])
      ])
    ])
  ]))
}