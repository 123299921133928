import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, mergeProps as _mergeProps, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d49bd11"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "menu-title" }
const _hoisted_3 = { class: "text-noWhiteSpaces" }
const _hoisted_4 = { class: "text-noWhiteSpaces" }
const _hoisted_5 = { class: "page-wrapper" }
const _hoisted_6 = { class: "menu-wrapper" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = { class: "text-noWhiteSpaces" }
const _hoisted_9 = { class: "text-noWhiteSpaces" }
const _hoisted_10 = { class: "desc" }
const _hoisted_11 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuBackButtons = _resolveComponent("MenuBackButtons")!

  return (_openBlock(), _createElementBlock("section", {
    id: _ctx.menuName,
    class: "menu page"
  }, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title[0]), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.title.slice(1)), 1)
      ])
    ]),
    (_ctx.backButtonData && _ctx.backButtonData.length > 0)
      ? (_openBlock(), _createBlock(_component_MenuBackButtons, {
          key: 0,
          buttonData: _ctx.backButtonData
        }, null, 8, ["buttonData"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuButtonsData, (button, index) => {
          return (_openBlock(), _createElementBlock("div", _mergeProps({
            key: index,
            class: [
          'menu-btn',
          button.title.toLowerCase().replace(/\s+/g, '-'),
          { 'short-btn': !button.bigButton },
          { 'disabled-btn': _ctx.isDisabled(button)}
        ],
            onMouseenter: _cache[0] || (_cache[0] = ($event: any) => (_ctx.playSound('menu_hover')))
          }, _ctx.getButtonAttributes(button)), [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", _hoisted_8, _toDisplayString(button.title[0]), 1),
                _createElementVNode("p", null, [
                  _createElementVNode("span", _hoisted_9, _toDisplayString(button.title.slice(1)), 1),
                  _createElementVNode("span", _hoisted_10, _toDisplayString(button.desc), 1)
                ])
              ]),
              _createElementVNode("img", {
                src: button.iconSrc
              }, null, 8, _hoisted_11)
            ])
          ], 16))
        }), 128))
      ])
    ])
  ], 8, _hoisted_1))
}