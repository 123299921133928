import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e2744124"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chartContainer" }
const _hoisted_2 = { class: "chartWrapper" }
const _hoisted_3 = {
  key: 0,
  class: "notAvailable"
}
const _hoisted_4 = { ref: "chartCanvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.data.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, "No data available."))
        : _createCommentVNode("", true),
      _createElementVNode("canvas", _hoisted_4, null, 512)
    ])
  ]))
}