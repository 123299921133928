import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "scorePage",
  class: "page"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GameModeLayout = _resolveComponent("GameModeLayout")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_GameModeLayout, {
      backButtonData: $setup.backButtonData,
      gameMode: $setup.gameMode,
      leaderboardFields: $setup.leaderboardFields,
      leaderboardSortByField: $setup.leaderboardSortByField
    }, null, 8, ["backButtonData", "gameMode", "leaderboardFields", "leaderboardSortByField"])
  ]))
}