import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-dd4206cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pixitesting",
  class: "page"
}
const _hoisted_2 = { class: "page-wrapper" }
const _hoisted_3 = { class: "page-container" }
const _hoisted_4 = { id: "pixicanvas" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToState(_ctx.PAGE_STATE.soloMenu)))
            }, "go Back"),
            _createElementVNode("button", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.openCanvasAsImageInNewTab && _ctx.openCanvasAsImageInNewTab(...args)))
            }, "open canvas in new tab")
          ]),
          _createElementVNode("div", null, [
            _createElementVNode("button", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.showBubbleSpriteSheet && _ctx.showBubbleSpriteSheet(...args)))
            }, "Bubbles")
          ])
        ])
      ])
    ])
  ]))
}