import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MenuLayout = _resolveComponent("MenuLayout")!

  return (_openBlock(), _createBlock(_component_MenuLayout, {
    backButtonData: [],
    title: 'blubb.io',
    menuName: 'startmenu',
    menuButtonsData: $setup.menuButtonsData
  }, null, 8, ["title", "menuButtonsData"]))
}